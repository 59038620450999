const initToggle = () => {
  const hotels = document.querySelector('.hotels');
  const button = document.querySelector('.hotels__toggle');

  button?.addEventListener('click', () =>
    hotels?.classList.toggle('hotels_closed'),
  );
};

const about = {
  init: () => {
    initToggle();
  },
};

export default about;
