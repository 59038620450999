import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';
import { useHotelContext } from 'features/hotel/contexts/hotel-context';
import { HotelActions } from 'features/hotel/contexts/hotel-context/types';
import { CloseButton } from 'common/elements/close-button';
import styles from './styles.module.scss';
import { useEffect } from 'react';

Swiper.use([Navigation, Pagination]);

export function HotelBody() {
  const [state, dispatch] = useHotelContext();
  const { name, address, link, text, images } = state;

  useEffect(() => {
    const slider = new Swiper(
      document.querySelector<HTMLElement>(`.${styles.slider}  .swiper`)!,
      {
        slidesPerView: 1,
        navigation: {
          // nextEl: `.${styles.next}`,
          // prevEl: `.${styles.prev}`,
          nextEl: '#hotel-next-btn',
          prevEl: '#hotel-prev-btn',
        },
      },
    );

    GLightbox({
      selector: `.${styles.slide}`,
      loop: true,
    });

    return () => slider.destroy();
  }, []);

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch({ type: HotelActions.ToggleModal })}
      />

      <h3 className={styles.name}>{name}</h3>

      <p className={styles.address}>{address}</p>

      <a className={styles.link} href={link} target="_blank" rel="noreferrer">
        Подробнее
      </a>

      <div className={styles.block}>
        <p className={styles.text}>{text}</p>

        <div className={styles.slider}>
          <div className="swiper">
            <div className="swiper-wrapper">
              {images.map((image) => (
                <div key={image} className="swiper-slide">
                  <a className={styles.slide} href={image}>
                    <img src={image} alt={name} />
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.nav}>
            <button id="hotel-prev-btn" className={styles.prev}></button>
            <button id="hotel-next-btn" className={styles.next}></button>
          </div>
        </div>
      </div>
    </div>
  );
}
