import { IHotelState, HotelActionsType, HotelActions } from './types';

export function hotelReducer(
  state: IHotelState,
  action: HotelActionsType,
): IHotelState {
  switch (action.type) {
    case HotelActions.SetValues:
      return {
        ...state,
        name: action.payload.name,
        address: action.payload.address,
        link: action.payload.link,
        text: action.payload.text,
        images: action.payload.images.split(','),
      };

    case HotelActions.ToggleModal:
      return {
        ...state,
        ui: {
          ...state.ui,
          modalIsOpen: !state.ui.modalIsOpen,
        },
      };

    default:
      throw new Error(`Action ${action} was not recognized`);
  }
}
