import { CloseButton } from 'common/elements/close-button';
import { useAppContext } from 'features/app/contexts/app-context';
import { AppActions } from 'features/app/contexts/app-context/types';
import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

const policyText = document.getElementById('policy-text');
const rulesText = document.getElementById('rules-text');
const cookieText = document.getElementById('cookie-text');
const principlesText = document.getElementById('principles-text');
const requisitesText = document.getElementById('requisites-text');

export function TextModalBody() {
  const [state, dispatch] = useAppContext();
  const contentRef = useRef<HTMLDivElement>(null);

  const [title, setTitle] = useState('');

  const type = state.textModalType;
  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    if (type === 'policy') {
      contentRef.current.innerHTML = policyText?.innerHTML ?? '';
      setTitle('Политика конфиденциальности');
    } else if (type === 'rules') {
      contentRef.current.innerHTML = rulesText?.innerHTML ?? '';
      setTitle('Пользовательское соглашение');
    } else if (type === 'cookie') {
      contentRef.current.innerHTML = cookieText?.innerHTML ?? '';
      setTitle('Политика использования cookie');
    } else if (type === 'principles') {
      contentRef.current.innerHTML = principlesText?.innerHTML ?? '';
      setTitle('Правила проживания');
    } else if (type === 'requisites') {
      contentRef.current.innerHTML = requisitesText?.innerHTML ?? '';
      setTitle('Реквизиты');
    }
  }, [type]);

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch({ type: AppActions.ToggleTextModal })}
      />

      <h3 className={styles.title}>{title}</h3>
      <div className={styles.content} ref={contentRef} />
    </div>
  );
}
