import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSlider = () => {
  new Swiper('.beach__slider .swiper', {
    loop: true,
    spaceBetween: 0,

    navigation: {
      nextEl: '.beach__next',
      prevEl: '.beach__prev',
    },

    // pagination: {
    //   el: '.complexes__pagination',
    //   clickable: true,
    // },

    // breakpoints: {
    //   768: {
    //     slidesPerView: 2,
    //     spaceBetween: 30,
    //   },
    //   1200: {
    //     slidesPerView: 4,
    //     spaceBetween: 30,
    //   },
    // },
  });
};

const initZoom = () => {
  GLightbox({
    selector: '.beach__slide',
    loop: true,
  });
};

const beach = {
  init: () => {
    initSlider();
    initZoom();
  },
};

export default beach;
