import { Dispatch, ReactNode } from 'react';

export enum AppActions {
  ToggleTextModal,
  ToggleThanks,
}

export type AppActionsType =
  | { type: AppActions.ToggleTextModal; payload?: TextModalType }
  | { type: AppActions.ToggleThanks; payload?: ThanksModalType };

export interface IAppState {
  textModalType?: TextModalType;
  thanksModalType?: ThanksModalType;

  ui: {
    textModalIsOpen: boolean;
    thanksIsOpen: boolean;
  };
}

export type TextModalType =
  | 'policy'
  | 'rules'
  | 'cookie'
  | 'principles'
  | 'requisites';
export type ThanksModalType = 'request' | 'addReview';

export type AppContextType = [IAppState, Dispatch<AppActionsType>];

export interface IAppProviderProps {
  children: ReactNode;
}
