import { Dispatch, ReactNode } from 'react';

export enum HotelActions {
  SetValues,
  ToggleModal,
}

export type HotelActionsType =
  | {
      type: HotelActions.SetValues;
      payload: {
        name: string;
        address: string;
        link: string;
        text: string;
        images: string;
      };
    }
  | { type: HotelActions.ToggleModal };

export interface IHotelState {
  name: string;
  address: string;
  link: string;
  text: string;
  images: string[];
  ui: {
    modalIsOpen: boolean;
  };
}

export type HotelContextType = [IHotelState, Dispatch<HotelActionsType>];

export interface IHotelProviderProps {
  children: ReactNode;
}
