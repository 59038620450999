import { Modal } from 'common/layout/modal';
import { Portal } from 'common/other/portal';
import { Transition } from 'common/other/transition';
import { useHotelContext } from 'features/hotel/contexts/hotel-context';
import { HotelActions } from 'features/hotel/contexts/hotel-context/types';
import { useEffect, useRef } from 'react';
import { HotelBody } from '../hotel-body';

const hotelButtons = Array.from(
  document.querySelectorAll<HTMLButtonElement>('.hotels__item'),
);

export function Hotel() {
  const [state, dispatch] = useHotelContext();
  const transitionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function toggle(this: HTMLButtonElement) {
      dispatch({ type: HotelActions.ToggleModal });
      dispatch({
        type: HotelActions.SetValues,
        payload: {
          name: this.dataset.name ?? '',
          address: this.dataset.address ?? '',
          link: this.dataset.link ?? '',
          text: this.dataset.text ?? '',
          images: this.dataset.images ?? '',
        },
      });
    }

    hotelButtons.forEach((button) => button?.addEventListener('click', toggle));

    return () =>
      hotelButtons.forEach((button) =>
        button?.removeEventListener('click', toggle),
      );
  }, [dispatch]);

  return (
    <Transition
      transitionRef={transitionRef}
      isActive={state.ui.modalIsOpen}
      timeout={300}
    >
      <Portal withBlocking>
        <Modal
          onOutsideClick={() => dispatch({ type: HotelActions.ToggleModal })}
          ref={transitionRef}
        >
          <HotelBody />
        </Modal>
      </Portal>
    </Transition>
  );
}
