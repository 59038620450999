import { Policy } from 'common/elements/policy';
import { Input } from 'common/form/input';
import { useAppContext } from 'features/app';
import { AppActions } from 'features/app/contexts/app-context/types';
import { useQuestionContext } from 'features/question/contexts/question-context';
import { QuestionActions } from 'features/question/contexts/question-context/types';
import { FormEvent } from 'react';
import styles from './styles.module.scss';

export function Question() {
  const [, appDispatch] = useAppContext();
  const [state, dispatch] = useQuestionContext();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // ym(87397023, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    setTimeout(
      () => appDispatch({ type: AppActions.ToggleThanks, payload: 'request' }),
      300,
    );

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Вопрос',
        name: state.name,
        phone: state.phone,
      }),
    });

    dispatch({ type: QuestionActions.Clear });
  }

  return (
    <form className={styles.module} onSubmit={handleSubmit}>
      <Input
        className={styles.name}
        placeholder="Имя"
        value={state.name}
        onChange={(event) =>
          dispatch({
            type: QuestionActions.SetName,
            payload: event.target.value,
          })
        }
      />

      <Input
        className={styles.phone}
        placeholder="Телефон"
        type="tel"
        // pattern="^\+?[0-9()\- ]+"
        pattern="^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$"
        required
        value={state.phone}
        onChange={(event) =>
          dispatch({
            type: QuestionActions.SetPhone,
            payload: event.target.value,
          })
        }
      />

      <Policy
        className={styles.policy}
        checked={state.policyIsChecked}
        onToggle={() => dispatch({ type: QuestionActions.TogglePolicy })}
        onClickButton={() =>
          appDispatch({ type: AppActions.ToggleTextModal, payload: 'policy' })
        }
        required
      />

      <button className={styles.button}>Отправить</button>
    </form>
  );
}
