import { IHotelState } from './types';

export const hotelInitialState: IHotelState = {
  name: '',
  address: '',
  link: '',
  text: '',
  images: [],
  ui: {
    modalIsOpen: false,
  },
};
