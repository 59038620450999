import { createContext, useContext, useReducer } from 'react';
import { hotelReducer } from './reducer';
import { hotelInitialState } from './state';
import { IHotelProviderProps, HotelContextType } from './types';

const HotelContext = createContext<HotelContextType | null>(null);

export function useHotelContext() {
  const hotel = useContext(HotelContext);
  if (hotel === null) {
    throw new Error('useHotelContext must be within HotelProvider');
  }
  return hotel;
}

export function HotelProvider({ children }: IHotelProviderProps) {
  const value = useReducer(hotelReducer, hotelInitialState);

  return (
    <HotelContext.Provider value={value}>{children}</HotelContext.Provider>
  );
}
