const initToggle = () => {
  const names = document.querySelectorAll('.vacancy__name');
  names.forEach((name) => {
    name.addEventListener('click', () => {
      const item = name.closest('.vacancy__item');
      if (item) {
        item.classList.toggle('vacancy__item_active');
      }
    });
  });
};

const vacancy = {
  init: () => {
    initToggle();
  },
};

export default vacancy;
