import { Slider } from 'common/layout/slider';
import { useAppContext } from 'features/app';
import { AppActions } from 'features/app/contexts/app-context/types';
import { useQuizContext } from 'features/quiz/contexts/quiz-context';
import { QuizActions } from 'features/quiz/contexts/quiz-context/types';
import { FormEvent } from 'react';
import { QuizControl } from '../quiz-control';
import { QuizOne } from '../quiz-one';
import { QuizThree } from '../quiz-three';
import { QuizTwo } from '../quiz-two';
import styles from './styles.module.scss';

export function Quiz() {
  const [, appDispatch] = useAppContext();
  const [state, dispatch] = useQuizContext();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!state.arrival || !state.departure) {
      return;
    }

    // ym(87397023, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    setTimeout(
      () => appDispatch({ type: AppActions.ToggleThanks, payload: 'request' }),
      300
    );

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: 'Квиз',
        arrival: state.arrival.toLocaleDateString(),
        departure: state.departure.toLocaleDateString(),
        adults: state.adults,
        babies: state.babies,
        name: state.name,
        phone: state.phone,
      }),
    });

    dispatch({ type: QuizActions.Clear });
  }

  return (
    <div className={styles.module}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Slider className={styles.slider} offsetBy={state.step - 1}>
          <QuizOne />
          <QuizTwo />
          <QuizThree />
        </Slider>
        <QuizControl />
      </form>
    </div>
  );
}
